#extra-charge-modal-error {
  display: none;
}

div#extra-charge-modal-body {
  padding: 24px 32px;
}

div#extra-charge-modal-inner {
  width: 900px !important;
}

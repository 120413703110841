select#remind-modal-hours-num {
  display: inline-block;
  width: 65px;
  vertical-align: middle;
  margin-left: 5px;
}

select#remind-modal-mins-num {
  display: inline-block;
  vertical-align: middle;
  width: 65px;
  margin-right: 5px;
}

div#remind-modal-body {
  padding: 24px 32px;
}

div#remind-modal-error {
  display: none;
}

a#set-remind-btn {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

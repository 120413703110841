@import 'kube-v6.5.2';
@import 'misc';
@import 'header';
@import 'sidebar';
@import 'locations';
@import 'orders';
@import 'pages';
@import 'dashboard';
@import 'ready';
@import 'remind';
@import 'cancel';
@import 'refund';
@import 'picked-up';
@import 'order';
@import 'invoices';
@import 'pincode-ui';
@import 'extra-charge';
@import 'manage-stock';
@import 'call-another-driver';

/* shared */

body#unauth-body {
  background: url(https://s3-us-west-1.amazonaws.com/mixbowl-assets/backgrounds/unauth-bg.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.blockquote-error {
  color: $color-error;
  border-left-color: $color-error;
}

.blockquote-success {
  color: $color-success;
  border-left-color: $color-success;
}

.blockquote-warning {
  color: $color-warning;
  border-left-color: $color-warning;
}

a.back-link {
  display: 'inline-block';
  color: $color-midgray;
  text-decoration: none;

  &:hover {
    color: $color-gray;
  }
}

.color-success {
  color: $color-success;
}

.color-error {
  color: $color-error;
}

.color-warning {
  color: $color-warning;
}

.color-midgray {
  color: $color-midgray;
}

.color-gray {
  color: $color-gray;
}

.pagination-btn {
  cursor: pointer !important;
}

p.query-summary-text {
  margin-top: 15px;
  margin-bottom: 0px;
  color: $color-midgray;
}

fieldset.fieldset-white {
  margin-top: 0;
  background-color: white;
  padding-bottom: 0;
}

// Main Color
.mix-primary-bg {
  background-color: #ed3e7d;

  &.on-hover {
    &:hover {
      background-color: #ed3e7d;
      opacity: .9;
    }
  }
}

.mix-primary-text {
  color: #ed3e7d;

  &.on-hover {
    &:hover {
      background-color: #ed3e7d;
      opacity: .9;
    }
  }
}

.ellipse {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.qty-box {
  display: inline-block;
  width: 36px;
  padding: 5px;
  // background-color: $color-midgray;
  color: $color-midgray;
  text-align: center;
}

// location status box open / close
.location-open-close-box {
  // border-radius: 100px;
  // padding: 7px 10px;
  padding: 10px;

  &.open {
    color: $color-success;
    // border: 1px solid $color-success;
  }

  &.closed {
    color: $color-error;
    // border: 1px solid $color-error;
  }
}

/* header.scss */

header {
  width: 100%;
  height: 60px;
  padding: 15px;
  border-bottom: 1px solid $color-silver;
  font-size: 30px;
  color: $color-midgray;
}

a#sidebar-toggle {
  display: inline-block;
  text-decoration: none;
  color: $color-midgray;
}

div#header-title {
  display: inline-block;
  margin-left: 10px;
}

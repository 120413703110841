.call-another-driver-btn {
  border-color: #ed3e7d !important;
  color: #ed3e7d !important;
}

#call-another-driver-modal-inner {
  width: 900px !important;
}

.redelivery-complete {
  color: #35beb1;
}

.redelivery-canceled {
  color: red;
}

.redelivery-requested {
  color: #ed3e7d;
}

.redelivery-enroute {
  color: #676b72;
}

#redelivery-status {
  padding-top: 0.5rem;
}

.redelivery-callout {
  border: 1px solid rgba(0,0,0,.1);
  padding: 1rem;
  margin-bottom: 1rem;
}

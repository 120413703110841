/* sidebar.scss */

div#locations-update-form-success,
div#locations-update-content-main,
div#locations-update-content-bank-warning,
div#locations-update-content-load-error,
div#locations-stripe-connect-success,
div#locations-stripe-connect-error,
div#locations-update-content-bank-connected,
div#locations-table-outer,
div#bank-modal-error,
div#locations-update-content-ach-warning,
div#locations-update-content-ach-verify,
div#bank-verify-modal-error,
div#locations-update-form-ach-status {
  display: none;
}

div#locations-content-box,
div#locations-create-content-box,
div#locations-update-content-box,
div#locations-stripe-connect-box {
  padding: 20px;
}

.select-time-width {
  width: 65px !important;
}

.select-time-width2 {
  width: 75px !important;
}

.select-time-width3 {
  width: 100px !important;
}

.set-hours-form-hours-remove {
  float: right;

  &:hover {
    color: $color-gray;
  }
}

table#locations-table {
  margin-top: 20px;
}

// tr.locations-table-row {
//   cursor: pointer !important;
// }


a.set-up-bank {
  text-decoration: none;
  float: right;
}

a#edit-bank-btn {
  color: $color-midgray;;
  background-color: white;
  border: 0px solid white;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: $color-midgray;;
    background-color: white;
    border: 0px solid white;
    opacity: .8;
  }

  &:disabled {
    color: $color-midgray;;
    background-color: white;
    border: 0px solid white;
    opacity: .8;
  }
}

// Places Autocomplete Container
// https://developers.google.com/maps/documentation/javascript/places-autocomplete
.pac-container {}

.pac-icon {
  font-size: 16px;
}

.pac-item {
  font-size: 16px;
  line-height: 24px;
  height: 44px;
  padding: 10px;
}

.pac-item-query {
  font-size: 16px;
}

.pac-matched {}

/* orders.scss */


div#orders-table-outer {
  display: none;
}

div#orders-content-box {
  padding: 20px;
}

table#orders-table {
  margin-top: 20px;
}

// tr.orders-table-row {
//   cursor: pointer !important;
// }

#orders-search-type {
  border-radius: 3px 0px 0px 3px;
  border-right: 0px;
}

#orders-search {
  border-radius: 0px;
}

/* invoices.scss */

div#invoices-table-outer {
  display: none;
}

div#invoices-content-box {
  padding: 20px;
}

table#invoices-table {
  margin-top: 20px;
}

// tr.invoices-table-row {
//   cursor: pointer !important;
// }

strong.invoices-status-box {
  display: inline-block;
  padding: 5px 10px;

  &.error {
    color: $color-error;
    background-color: transparent;
    border: 1px solid $color-error;
  }

  &.warning {
    color: $color-warning;
    background-color: transparent;
    border: 1px solid $color-warning;
  }

  &.success {
    color: $color-success;
    background-color: transparent;
    border: 1px solid $color-success;
  }
}

#manage-stock-modal-error {
  display: none;
}

div#manage-stock-modal-body {
  padding: 24px 32px;
}

div#manage-stock-modal-inner {
  width: 900px !important;
}

div#manage-stock-divider {
  height: 1px;
  background-color: $color-silver;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.manage-stock-product-img {
  height: 100px;
  width: 100px;
  max-width: 100%;
}

#manage-stock-products-wrapper,
#manage-stock-options-wrapper {
  border-bottom: thin solid $color-silver;
}

.manage-stock-product-out-of-stock-btn,
.manage-stock-option-out-of-stock-btn {
  border-color: #ed3e7d !important;
  color: #ed3e7d !important;
}

.manage-stock-row {
  padding: 0.5rem 0;
  border-bottom: solid thin #e0e1e1;
  margin-top: 1rem;
}

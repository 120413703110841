#refund-modal-error {
  display: none;
}

div#refund-modal-body {
  padding: 24px 32px;
}

div#refund-modal-inner {
  width: 900px !important;
}

div#refund-divider {
  height: 1px;
  background-color: $color-silver;
}

.remind-order-option {
  color: $color-midgray;
}

.td-mid {
  vertical-align: middle !important;
}

#refund-order-fees,
#refund-order-extra-charges {
  display: none;
}

.refund-fee-total,
.refund-extra-charge-total {
  float: right;
}

#order-container {
  color: $color-midgray;
}

.order-outer {
  border: 1px solid $color-silver;
  border-radius: 2px;
  min-height: 50px;
}

.order-box {
  padding: 20px;

  &.with-border-top {
    border-top: 1px solid $color-silver;
  }
}

#order-location-address,
#order-delivery-address, {
  color: $color-gray;
}

#order-delivery-details,
#order-total-refunded-outer,
#order-canceled-message,
#order-reminder-message,
#order-ready-message,
#order-picked-up-message,
#order-mixbowl-delivery-cost-outer,
#order-systemMarkedPickedUp,
#order-refund-history-outer,
#order-note-update-form-successs,
#order-positive-feedback,
#order-negative-feedback {
  display: none;
}

#order-date {
  float: right;
  color: $color-midgray;
  font-size: 14px;
  font-weight: normal;
}

#view-customer-order-status-page {
  cursor: pointer;
}

// STATUS
.order-status {
  padding: 5px 5px;

  &.new {
    background-color: $color-error;
    border: 1px solid $color-error;
    color: white;
    text-transform: uppercase;
  }

  &.preparing, &.ready {
    border: 1px solid $color-midgray;
    color: $color-midgray;
  }

  &.refunded {
    border: 1px solid $color-gray;
    color: $color-gray;
  }

  &.in-transit {
    border: 1px solid $color-darkgray;
    color: $color-darkgray;
  }

  &.complete {
    border: 1px solid $color-success;
    color: $color-success;
  }

  &.canceled {
    border: 1px solid $color-midgray;
    color: $color-midgray;
    text-decoration: line-through;
  }
}

#order-negative-feedback-resolved, #order-negative-feedback-resolved-undo {
  float: right;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.label-box {
  border: 1px solid $color-gray;
  padding: 10px;
  margin: 20px 0px;
}
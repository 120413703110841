// main dashboard split
div#dash-left {
  float: left;
  width: 300px;
  border-right: 1px solid $color-silver;
}

div#dash-right {
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  color: $color-midgray;
}

// left header split
div#dash-left-header {
  height: 60px;
  padding: 15px;
  border-bottom: 1px solid $color-silver;
  font-size: 30px;
  color: $color-midgray;
}

div#dash-left-header-left {
  width: 50px;
  float: left;
}

div#dash-left-header-right {
  overflow: hidden;
  text-overflow: ellipsis;
}

select#dash-locations {
  border: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: -1px;

  &:focus {
    outline: none;
    text-shadow: 0 0 0 #000;
  }
}

div#dash-left-orders-history {
  padding: 20px;
  text-align: center;
}

// right header split
div#dash-right-header {
  height: 60px;
  padding: 10px 15px;
  border-bottom: 1px solid $color-silver;
  font-size: 16px;
  color: $color-midgray;
}

span#dash-right-header-order-no {
  line-height: 40px;
  padding-left: 10px;
}

button.dash-right-header-btn,
button#refund-order-btn,
button#extra-charge-btn,
button#print-order-btn,
button#receipt-order-btn,
button#fax-order-btn,
button#dash-manage-stock-btn,
a#dash-left-orders-history-btn {
  color: $color-midgray;
  background-color: white;
  border: 1px solid $color-midgray;
  cursor: pointer;

  &:hover {
    color: $color-midgray;
    background-color: white;
    border: 1px solid $color-midgray;
    opacity: .8;
  }

  &:disabled {
    color: $color-midgray;
    background-color: white;
    border: 1px solid $color-midgray;
    opacity: .8;
  }
}

button#dash-manage-stock-btn {
  display: none;
}

button#dash-text-alerts-btn {
  display: inline-block;
  margin-left: 15px;
  cursor: pointer;
}

// ORDER BOX
div#dash-left-orders {
  overflow: scroll;
}

div.dash-order-box {
  height: 80px;
  border-bottom: 1px solid $color-silver;
  padding: 20px 10px;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &.selected {
    background-color: $color-silver;
  }

  &:hover {
    background-color: $color-silver;
    cursor: pointer;
  }
}

span.dash-order-box-status {
  display: inline-block;
  margin-right: 5px;

  &.new {
    background-color: $color-error;
    border: 1px solid $color-error;
    color: white;
    padding: 0px 5px;
    text-transform: uppercase;

    &.flash {
      -webkit-animation: alert-flash-ani 1s infinite;  /* Safari 4+ */
      -moz-animation: alert-flash-ani 1s infinite;  /* Fx 5+ */
      -o-animation: alert-flash-ani 1s infinite;  /* Opera 12+ */
      animation: alert-flash-ani 1s infinite;  /* IE 10+, Fx 29+ */
    }
  }

  &.preparing, &.ready, &.enroute {
    border: 1px solid $color-midgray;
    color: $color-midgray;
    padding: 0px 5px;
  }

  &.refunded {
    border: 1px solid $color-gray;
    color: $color-gray;
    padding: 0px 5px;
  }

  &.in-transit {
    border: 1px solid $color-darkgray;
    color: $color-darkgray;
    padding: 0px 5px;
  }

  &.scheduled {
    border: 1px solid $color-warning;
    color: $color-warning;
    padding: 0px 5px;

    &.flash {
      -webkit-animation: alert-flash-ani 1s infinite;  /* Safari 4+ */
      -moz-animation: alert-flash-ani 1s infinite;  /* Fx 5+ */
      -o-animation: alert-flash-ani 1s infinite;  /* Opera 12+ */
      animation: alert-flash-ani 1s infinite;  /* IE 10+, Fx 29+ */
    }
  }

  &.complete {
    // border: 1px solid $color-success;
    color: $color-success;
  }

  &.canceled {
    // border: 1px solid $color-error;
    // color: $color-error;
    text-decoration: line-through;
  }
}

span.dash-thumbs {
  float: right;

  i.dash-right-thumb {
    display: inline-block;
    margin-left: 5px;
  }
}

// New Order Flash Box
div#dash-new-order-flash {
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 300px;
  top: 60px;
  background-color: $color-error;
  color: white;
  z-index: 10;
  font-size: 36px;
  padding-top: 60px;

  i {
    font-size: 240px;
  }
}

button#dash-view-new-order-flash {
  background-color: $color-error;
  color: white;
  border: 3px solid $color-error;
  cursor: pointer;
}

div#dash-close-new-order-flash-wrapper {
  position: absolute;
  bottom: 40px;
  text-align: center;
  width: 100%;
}

a#dash-close-new-order-flash {
  font-size: 18px;
  color: $color-error;
  cursor: pointer;
}

// Pause order screen
div#dash-pause-order-screen {
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 300px;
  top: 60px;
  background-color: $color-warning;
  color: white;
  z-index: 8;
  font-size: 32px;
  padding-top: 120px;

  i {
    font-size: 240px;
  }
}

button#dash-allow-orders-btn {
  color: white;
  background-color: $color-warning;
  border: 3px solid white;
  cursor: pointer;

  &:hover {
    color: white;
    background-color: $color-warning;
    border: 3px solid white;
    opacity: .8;
  }

  &:disabled {
    color: white;
    background-color: $color-warning;
    border: 3px solid white;
    opacity: .8;
  }
}

// Dashboard filler
div#dash-filler {
  position: absolute;
  background-color: white;
  bottom: 0;
  right: 0;
  left: 300px;
  top: 60px;
  color: $color-gray;
  z-index: 0;
  font-size: 24px;
  padding-top: 120px;
}

div#dash-filler-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -120px;
}

img#dash-filler-logo {
  width: 240px;
  height: auto;
}

// Dashboard order
div#dash-order {
  display: none;
  position: absolute;
  overflow: scroll;
  bottom: 0;
  right: 0;
  left: 300px;
  top: 60px;
  z-index: 2;
  padding: 20px;
  background-color: white;
}

div#dash-order-pay-at-store-alert,
div#dash-order-reminder-alert,
div#dash-order-ready-alert,
div#dash-order-cancel-alert,
div#dash-order-picked-up-alert,
div#dash-loading-screen,
div#dash-order-extra-charges-outer {
  display: none;
}

div#dash-loading-screen {
  background-color: white;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 300px;
  top: 60px;
  z-index: 10000000;
}

div#dash-loading-screen-icon {
  position:absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

// original hollow
// button#cancel-order-btn {
//   color: $color-error;
//   background-color: white;
//   border: 1px solid $color-error;
//   cursor: pointer;

//   &:hover {
//     color: $color-error;
//     background-color: white;
//     border: 1px solid $color-error;
//     opacity: .8;
//   }

//   &:disabled {
//     color: $color-error;
//     background-color: white;
//     border: 1px solid $color-error;
//     opacity: .8;
//   }
// }

button#cancel-order-btn {
  color: white;
  background-color: $color-error;
  border: 1px solid $color-error;
  cursor: pointer;

  &:hover {
    color: white;
    background-color: $color-error;
    border: 1px solid $color-error;
    opacity: .8;
  }

  &:disabled {
    color: white;
    background-color: $color-error;
    border: 1px solid $color-error;
    opacity: .8;
  }
}

span#dash-order-name {
  font-size: 24px;
  line-height: 38px;
}

button.dash-order-btn {
  display: inline-block;
}

div#dash-order-info {
  margin-top: 30px;
  font-size: 16px;
  color: $color-midgray;
}

div#dash-order-date {
  padding-top: 10px;
  font-size: 16px;
  color: $color-midgray;
}

// ARROWS
div#arrow-down-left {
  position: fixed;
  right: 10px;
  bottom: 75px;
  animation: updownarrow 0.5s ease-out 0s infinite alternate;
}

div#arrow-down-right {
  position: fixed;
  left: 310px;
  bottom: 75px;
  animation: updownarrow 0.5s ease-out 0s infinite alternate;
}

/* The animation code */
@keyframes updownarrow {
  0% {
    bottom: 75px;
  }
  100% {
    bottom: 125px;
  }
}

div.dash-order-accept-btn {
  // display: none;
  position: fixed;
  left: 310px;
  right: 10px;
  bottom: 10px;
  height: 60px;
  line-height: 60px;
  color: white;
  background-color: $color-success;
  font-size: 18px;
  text-align: center;
  border-top: 1px solid $color-success;
  cursor: pointer;
  border-radius: 3px;
}

div#picked-up-order-btn {
  display: none;
  position: fixed;
  left: 310px;
  right: 10px;
  bottom: 10px;
  height: 60px;
  line-height: 60px;
  color: white;
  background-color: $color-midgray;;
  font-size: 18px;
  text-align: center;
  border: 1px solid $color-midgray;;
  cursor: pointer;
  border-radius: 3px;
}

div#dash-order-msg-to-store {
  color: $color-error;
}

div.dash-order-item-category {
  color: $color-gray;
  font-style: italic;
  font-size: 12px;
  padding-left: 56px;
}

div.dash-order-item-product {
  color: $color-midgray;
  font-size: 24px;
}

div.dash-order-item-option {
  margin-left: 56px;
  color: $color-gray;
  font-size: 18px;
}

div.dash-order-item-total {
  margin-left: 56px;
  color: $color-gray;
  font-size: 18px;
}

div.dash-order-item-price {
  line-height: 32px;
}

div.dash-order-item-note {
  margin-left: 56px;
  color: $color-error;
  font-size: 14px;
}

div#dash-order-total {
  font-size: 20px;
  font-weight: bold;
  // margin-bottom: 20px;
}

div.dash-order-summary-left {
  display: inline-block;
  vertical-align: middle;
  width: 50%;
  text-align: left;
}

div.dash-order-summary-right {
  display: inline-block;
  vertical-align: middle;
  width: 50%;
  text-align: right;
}

div.dash-order-item-left {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

div.dash-order-item-right {
  display: inline-block;
  vertical-align: middle;
  width: 100px;
  text-align: right;
  float: right;
}

a#picked-up-alert-link {
  text-decoration: underline;
}

// Blinking CSS effect
.alert-flash {
  -webkit-animation: alert-flash-ani 1s infinite;  /* Safari 4+ */
  -moz-animation: alert-flash-ani 1s infinite;  /* Fx 5+ */
  -o-animation: alert-flash-ani 1s infinite;  /* Opera 12+ */
  animation: alert-flash-ani 1s infinite;  /* IE 10+, Fx 29+ */
}

.alert-flash-rev {
  -webkit-animation: alert-flash-ani-rev 1s infinite;  /* Safari 4+ */
  -moz-animation: alert-flash-ani-rev 1s infinite;  /* Fx 5+ */
  -o-animation: alert-flash-ani-rev 1s infinite;  /* Opera 12+ */
  animation: alert-flash-ani-rev 1s infinite;  /* IE 10+, Fx 29+ */
}

.alert-flash-color {
  -webkit-animation: alert-flash-color-ani 1s infinite;  /* Safari 4+ */
  -moz-animation: alert-flash-color-ani 1s infinite;  /* Fx 5+ */
  -o-animation: alert-flash-color-ani 1s infinite;  /* Opera 12+ */
  animation: alert-flash-color-ani 1s infinite;  /* IE 10+, Fx 29+ */
}

.alert-flash-color-rev {
  -webkit-animation: alert-flash-color-ani-rev 1s infinite;  /* Safari 4+ */
  -moz-animation: alert-flash-color-ani-rev 1s infinite;  /* Fx 5+ */
  -o-animation: alert-flash-color-ani-rev 1s infinite;  /* Opera 12+ */
  animation: alert-flash-color-ani-rev 1s infinite;  /* IE 10+, Fx 29+ */
}

@-webkit-keyframes alert-flash-ani {
  0%, 49% {
    border: 1px solid $color-error;
    background-color: $color-error;
    color: white;
  }
  50%, 100% {
    border: 1px solid $color-error;
    background-color: white;
    color: $color-error;
  }
}

@-webkit-keyframes alert-flash-ani-rev {
  0%, 49% {
    background-color: white;
    color: $color-error;
  }
  50%, 100% {
    background-color: $color-error;
    color: white;
  }
}

@-webkit-keyframes alert-flash-color-ani {
  0%, 49% {
    color: $color-error;
  }
  50%, 100% {
    color: white;
  }
}

@-webkit-keyframes alert-flash-color-ani-rev {
  0%, 49% {
    color: white;
  }
  50%, 100% {
    color: $color-error;
  }
}

div.dash-order-divider {
  margin: 20px 0px;
  background-color: $color-silver;
  height: 1px;
}

// yellow outline button
.yellow-btn-solid {
  border: 1px solid $color-warning;
  background-color: $color-warning;
  color: white;

  &:hover {
    color: white !important;
    background-color: $color-warning !important;
  }
}

.yellow-btn-outline {
  border: 1px solid $color-midgray;
  background-color: white;
  color: $color-midgray;

  &:hover {
    color: $color-midgray !important;
    background-color: white !important;
  }
}

#no-internet {
  display: none;
  position: fixed; top: 0; left: 0; right: 0;
  height: 60px;
  z-index: 10;
}

#ios-sound-check-div {
  display: none;
  position: fixed; bottom: 10px; left: 10px;
  z-index: 10;
}

span.dash-order-item-out-of-stock {
  margin-left: 56px;
  color: $color-focus;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}

span.dash-order-option-out-of-stock {
  color: $color-focus;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}

.dash-order-option-out-of-stock-text {
  color: $color-error;
  font-size: 12px;
}

.dash-order-item-out-of-stock-text {
  color: $color-error;
  font-size: 12px;
  margin-left: 56px;
}


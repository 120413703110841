/* sidebar.scss */

a.sidebar-item {
  li {
    i.fa {
      float: right;
      line-height: 32px;
    }

    margin-bottom: 15px;
  }

  &:hover {
    color: $color-black;
  }

  color: $color-midgray;
  text-decoration: none;
}

.sidebar-mixbowl-logo-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  width: 250px;
  text-align: center;
}

.sidebar-mixbowl-logo {
  width:  125px;
  height: auto;
}

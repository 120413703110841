#ready-minus-btn, #ready-plus-btn {
  display: inline-block;
  vertical-align: middle;
  width: 100px;
  height: 100px;
  margin-top: 25px;
  padding: 20px 5px;
  font-size: 64px;
  background-color: $color-midgray;
  color: white;
  text-align: center;
}

#ready-num-box {
  color: $color-midgray;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  padding: 50px 5px;
  width: 150px;
  height: 150px;
  // border-radius: 140px;
  // border: 3px solid $color-midgray;
}

#ready-num {
  font-size: 64px;
}
